// import { height, width } from '@mui/system';
// import React, { Component } from 'react';

// class Carousel extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentIndex: 0,
//     };

//     this.carouselContainer = React.createRef();
//     this.carouselSlides = [
//       '/assets/images/crsl1.png', 
//     //   '/assets/images/crsl2.png',
//     //   '/assets/images/crsl3.png',
//     ];

//     this.showSlide = this.showSlide.bind(this);
//   }

//   componentDidMount() {
//     // this.intervalId = setInterval(this.nextSlide, 3000);
//     this.showSlide(this.state.currentIndex);
//   }

//   componentWillUnmount() {
//     clearInterval(this.intervalId);
//   }

//   showSlide(index) {
//     this.carouselSlides.forEach((slide, i) => { 
//       this.carouselContainer.current.children[i].style.transform = `translateX(${100 * (i - index)}%)`;
//     });
//   }

//   nextSlide = () => {
//     const { currentIndex } = this.state;
//     this.setState({ currentIndex: (currentIndex + 1) % this.carouselSlides.length });
//     this.showSlide((currentIndex + 1) % this.carouselSlides.length);
//   }

//   prevSlide = () => {
//     const { currentIndex } = this.state;
//     const newIndex = (currentIndex - 1 + this.carouselSlides.length) % this.carouselSlides.length;
//     this.setState({ currentIndex: newIndex });
//     this.showSlide(newIndex);
//   }

//   render() {
//     return React.createElement('div', { className: 'carousel-container', ref: this.carouselContainer,style: { flexDirection: 'row' }},
//       this.carouselSlides.map((slide, i) => React.createElement('div', {
//         key: i,
//         className: 'carousel-slide',
//         style: { flex: 'auto', width: '100%',height: '140px', width: '420px'  },
//       },
//         React.createElement('img', { src: slide, alt: `Slide ${i + 1}`
//           ,style: { height: '470px', width: '1400px' }

//     }

//         )
//       ))
//     );
//   }
// }

// export default Carousel;

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';

export default function Carousel() {
  const sliders = [
    {
      id: "1",
      image: "/assets/images/crsl1.png"
    },
    {
      id: "2",
      image: "/assets/images/crsl2.png"
    },
    {
      id: "3",
      image: "/assets/images/crsl3.png"
    },
    {
      id: "4",
      image: "/assets/images/crsl4.png"
    },
    {
      id: "5",
      image: "/assets/images/crsl5.png"
    },
    {
      id: "6",
      image: "/assets/images/crsl6.png"
    }
  ]
  return (
    <div>
      <Swiper
        cssMode={true}
        navigation={false}
        pagination={true}
        mousewheel={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
        className="mySwiper"
      >
        {sliders && sliders.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div
                className="swiper-slide-content"
                style={{
                  backgroundImage: `url("${item.image}")`,
                }}
              />

            </SwiperSlide>
          )
        })}
      </Swiper>
      <style jsx>{`
        .mySwiper {
          height: 45vh;
          width: 100%;
        }

        @media (max-width: 767px) {
          .mySwiper {
            height: 20vh;
          }
        }

        .swiper-slide-content {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          height: 100%;
          width: 100%;
          border-radius: 20px;
        }
      `}</style>
    </div>
  )
}