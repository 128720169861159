import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    slot: {
        width: 30,
        marginRight: 5
    }
});

export default function Rules() {
    const classes = useStyles();
    return <img src="/assets/images/new_icons/faq.png" className={classes.icon} alt="Bonus" />
}
