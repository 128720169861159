import React from 'react';

// Create a Context for the language using the legacy context API
const LanguageContext = React.createContext();

class LanguageProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'en', // Default language
            changeLanguage: this.changeLanguage,
        };
    }

    changeLanguage = (lang) => {
        this.setState({ language: lang });
    };

    render() {
        return (
            <LanguageContext.Provider value={this.state}>
                {this.props.children}
            </LanguageContext.Provider>
        );
    }
}

export { LanguageProvider, LanguageContext };
