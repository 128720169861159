import React from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    icon: {
        width: 36,
        marginRight: 5,
        marginTop: 5
    }
});

export default function Deposit() {
    const classes = useStyles();
    return <img src="/assets/images/new_icons/deposit.png" className={classes.icon} alt="Deposit" />
}
