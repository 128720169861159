
import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Logo from "./../Logo/index";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ReactTooltip from "react-tooltip";
import LanguageSelector from '../../../../LanguageSelector';
import PerfectScrollbar from "perfect-scrollbar";
import {
  __,
  isMobile,
  wait,
  CRISTP_ID,
  addNewStyle,
  SITE_URL,
  decode,
} from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";

import AffiliateIcon from "../Sidebar/Icons/Affiliate";
import GamesIcon from "../Sidebar/Icons/Games";
import HomeIcon from "../Sidebar/Icons/Home";
import DepositIcon from "../Sidebar/Icons/Desposit";
import SettingIcon from "../Sidebar/Icons/Setting";
import BonusIcon from "../Sidebar/Icons/Vip";
import RackeIcon from "../Sidebar/Icons/Bonus";
import FaqIcon from "../Sidebar/Icons/Faq";
import LiveSupportIcon from "../Sidebar/Icons/LiveSupport";
import VipIcon from "../Sidebar/Icons/Vip";
import Bitcoin from "../Sidebar/Bitcoin";
import Social from "../Sidebar/Social";
import Games from "../Sidebar/Games";
import Verify from "../Sidebar/Verify";

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      games: [],
      showGames: false,
    };
    this.handleResize = this.handleResize.bind(this);
    this.toggleGames = this.toggleGames.bind(this);
  }

  componentDidMount() {
    if (this.state.games.length === 0) socket.emit(C.GAMES);

    wait(500).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);

    socket.on(C.GAMES, (data) => {
      this.setState({ games: decode(data), loading: false });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    if (isMobile()) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }

  toggleGames() {
    this.setState({ showGames: !this.state.showGames });
  }

  render() {
    return (
      <span>
        {this.state.show && (
          <ul className="list-unstyled topbar-nav mb-0 menu-items">
            <MobileDrawer
              games={this.state.games}
              t={this.props.t}
              showGames={this.state.showGames}
              toggleGames={this.toggleGames}
            />
          </ul>
        )}
      </span>
    );
  }
}

const useStyles = makeStyles({
  list: {
    width: 200, // Reduced width
  },
  fullList: {
    width: 200, // Reduced width
  },
  drawer: {
    color: "#FFF",
  },
  logo: {
    margin: 0,
    padding: "0px 12px 0",
    borderBottom: "1px solid #1c1f2d",
  },
  logoImage: {
    verticalAlign: "top",
  },
  links: {
    marginTop: 5,
    padding: "10px 12px",
    display: "block",
  },
  link: {
    fontSize: "26px !important", // Increased font size
  },
  icon: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    background: "#393d51",
    padding: 6,
  },
});

function MobileDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const drawerRef = useRef(null);

  useEffect(() => {
    if (drawerRef.current) {
      new PerfectScrollbar(drawerRef.current);
    }
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const fixer = (game) => {
    if (game === "heads-or-tails") return "heads";
    if (game === "hash-dice") return "hash dice";
    if (game === "classic-dice") return "classic dice";
    if (game === "rps") return "plinko";
    return __.replace(game, "_", " ");
  };

  const list = (anchor) => (
    <div
      ref={drawerRef}
      className={clsx(classes.list, {
        [classes.drawer]: "left",
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
  <List sx={{ paddingTop: '0 !important' }}>
        <Link to={'/'}>
        <ListItem button key="home" onClick={toggleDrawer(anchor, false)}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" className={classes.link} />
        </ListItem>
        </Link>
        <ListItem button key="games" onClick={props.toggleGames}>
          <ListItemIcon>
            <GamesIcon />
          </ListItemIcon>
          <ListItemText primary="Original Games" className={classes.link} />
        </ListItem>
        {props.showGames &&
          props.games.map(
            (item, index) =>
              !item.disabled && (
                <Link
                  key={__.toString(index)}
                  to={"/" + item.prefix}
                  onClick={toggleDrawer(anchor, false)}
                >
                  <ListItem button key={item}>
                    <ListItemIcon>
                      <img
                        className={clsx(classes.icon) + " mr-1"}
                        src={
                          "/assets/images/icons/" + fixer(item.prefix) + ".png"
                        }
                        alt={item.prefix}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={clsx(classes.link)}
                      primary={item.name}
                    />
                  </ListItem>
                </Link>
              )
          )}
          
        <Link to={'/bonus'} >
        <ListItem button key="bonus" onClick={toggleDrawer(anchor, false)}>
          <ListItemIcon>
            <BonusIcon />
          </ListItemIcon>
          <ListItemText primary="Bonus" className={classes.link} />
        </ListItem>
        </Link>
        <Link to={'/affiliate'} >
        <ListItem button key="affiliate" onClick={toggleDrawer(anchor, false)}>
          <ListItemIcon>
            <AffiliateIcon />
          </ListItemIcon>
          <ListItemText primary="Affiliate" className={classes.link} />
        </ListItem>
        </Link>
        <Link to={'/rakeback'} >
        <ListItem button key="rakeback" onClick={toggleDrawer(anchor, false)}>
          <ListItemIcon>
            <RackeIcon />
          </ListItemIcon>
          <ListItemText primary="Rakeback" className={classes.link} />
        </ListItem>
        </Link>
        <Link to={'/faq'} >
        <ListItem button key="faq" onClick={toggleDrawer(anchor, false)}>
          <ListItemIcon>
            <FaqIcon />
          </ListItemIcon>
          <ListItemText primary="FAQ" className={classes.link} />
        </ListItem>
        </Link>
        {/* <Link to={'/deposite'} >
        <ListItem button key="desposit" onClick={toggleDrawer(anchor, false)}>
          <ListItemIcon>
            <DepositIcon />
          </ListItemIcon>
          <ListItemText primary="Deposit" className={classes.link} />
        </ListItem>
        </Link> */}
        <Link to={'/setting'} >
        <ListItem button key="setting" onClick={toggleDrawer(anchor, false)}>
          <ListItemIcon>
            <SettingIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" className={classes.link} />
        
        </ListItem>
        </Link>
      </List>
       <li className="d-inline-block">
              <Link
                to="/about-us"
                className={clsx(classes.links)}
                onClick={toggleDrawer(anchor, false)}
              >
                <span style={{color:"#39FF14"}} className={classes.link} >About Us</span>
              </Link>
            </li>
      <div className="sidebar-footer">
        <div className="btn-group" aria-label="Basic example" role="group">
          <button
            type="button"
            className="btn btn-sm btn-soft-danger btn-rounded active"
          >
            <i className="mdi mdi-gamepad-variant"></i> Casino
          </button>
          <button
            onClick={(e) => this.changeType("sports")}
            type="button"
            className="btn btn-sm btn-rounded btn-soft-info"
          >
            <i className="mdi mdi-basketball"></i> Sports
          </button>
        </div>
      </div>
    </div>
  );

  const anchor = "left";
  const { t } = props;

  let loaded = false;

  const support = () => {
    var d, s;
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISTP_ID;

    if (!loaded) {
      console.log("support");
      loaded = true;
      (function () {
        d = document;
        s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.id = "chtt";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    }

    addNewStyle("#crisp-chatbox {display:block !important;}");
    addNewStyle(
      ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
    );

    wait(2000).then(() => {
      var close = document.querySelector(".cc-wdhl");
      if (close === null) return;
      close.addEventListener("click", function (event) {
        addNewStyle("#crisp-chatbox {display:none !important;}");
        document.getElementById("chtt").src = "";
      });
    });
  };

  return (
    <>
      <React.Fragment key={anchor}>
        <li className={"nav-link ml-0"} onClick={toggleDrawer(anchor, true)}>
          <i className={"mdi mdi-menu text-white font-20"} />
        </li>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          <ul className={clsx(classes.logo)}>
            <li>
              <Link to="/" className="logo">
              
              {/* <img
  src="/assets/images/lgg.png"
  className="logo-sm-small"
  alt="Logo"
  style={{
    width: '80%',
    maxWidth: '120px',
    height: 'auto',
    display: 'block',
    margin: '20px auto',
    objectFit: 'contain'
  }}
/> */}

<div style={{
  width: '100%',
  height: '100px',
  // padding: '10% 5% 10% 2%', 
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
}}>
  <img
    src="/assets/images/lgg.png"
    className="logo-sm-small"
    alt="Logo"
    style={{
      width: 'auto', 
      maxWidth: '160px',
      height: 'auto',
      objectFit: 'contain'
    }}
  />
</div>
              </Link>
            </li>
            {/* <li className="d-inline-block">
              <Link
                to="/about-us"
                className={clsx(classes.links)}
                onClick={toggleDrawer(anchor, false)}
              >
                <span style={{color:"#39FF14"}} className={classes.link} >About Us</span>
              </Link>
            </li> */}
          </ul>
          {list(anchor)}
          
<div className="footer-coin">
<p className="mt-4" style={{ color: '#ffbf09' }}>Accepted Currency</p>
                  <img
                    className="img-fluid"
                    src="/assets/images/btc.png"
                    alt="Coin"
                  />
                  <img
                    className="img-fluid"
                    src="/assets/images/eth.png"
                    alt="Coin"
                  />
                  <img
                    className="img-fluid"
                    src="/assets/images/ltc.png"
                    alt="Coin"
                  />
                  <img
                    className="img-fluid"
                    src="/assets/images/bch.png"
                    alt="Coin"
                  />
                  <img
                    className="img-fluid"
                    src="/assets/images/tron.png"
                    alt="Coin"
                  />
                  <img
                    className="img-fluid"
                    src="/assets/images/doge.png"
                    alt="Coin"
                  />
                  <img
                    className="img-fluid"
                    src="/assets/images/usdt.png"
                    alt="Coin"
                  />
                  {/* <img
                    className="img-fluid"
                    src="/assets/images/mkr.png"
                    alt="Coin"
                  />
                  <img
                    className="img-fluid"
                    src="/assets/images/busd.png"
                    alt="Coin"
                  /> */}
                  <img
                    className="img-fluid"
                    src="/assets/images/usdc.png"
                    alt="Coin"
                  />
                  {/* <img
                    className="img-fluid"
                    src="/assets/images/tusd.png"
                    alt="Coin"
                  />
                  <img
                    className="img-fluid"
                    src="/assets/images/usdp.png"
                    alt="Coin"
                  /> */}
                </div>
                {/* <div style={{
      width: '250px',
      padding: '20px',
      backgroundColor: '#f4f4f4',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}>
      <LanguageSelector />
    </div> */}
        </Drawer>
      </React.Fragment>
    </>
  );
}

export default MobileMenu;
