import React from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    icon: {
        width: 36,
        marginRight: 5,
        marginTop: 5
    }
});

export default function Setting() {
    const classes = useStyles();
    return <img src="/assets/images/new_icons/setting.png" className={classes.icon} alt="Setting" />
}
