import React, { Component } from 'react';
import { LanguageContext } from '../src/context/LanguageContext';

class LanguageSelector extends Component {
    static contextType = LanguageContext;

    render() {
        const { language, changeLanguage } = this.context;

        if (!language || !changeLanguage) {
            throw new Error("LanguageContext not found. Please ensure the LanguageProvider is correctly wrapped around your component tree.");
        }

        return (
            <select value={language} onChange={(e) => changeLanguage(e.target.value)}>
                <option value="en">English</option>
                <option value="ar">Arabic</option>
            </select>
        );
    }
}

export default LanguageSelector;
