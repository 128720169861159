import React from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    icon: {
        width: 24,
        marginRight: 5,
        marginTop: 8,
        color:'#ffbf09'
    }
});

export default function Affiliate() {
    const classes = useStyles();
    
    return <img src="/assets/images/new_icons/affiliate.png" className={classes.games} alt="Affiliate" />
}
