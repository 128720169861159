import React from 'react';
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons";
import storage from "../../../Storage";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import { isMobile, Event, wait, isValidNumber, forceSatoshiFormat, __, sendNotfication, playAudio } from "../../../Helper";
import C from "../../../Constant";

class Bet extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            bet: 'manual',
            buttonText: 'Roll',
            started: false,
            isLogged: storage.getKey("logged") !== null ? true : false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            disabled: false,
            payout: '1.0102',
            type: 'low',
            betNumber: 10,
            firstBetNumber: 10,
            onWin: 0,
            onLost: 0,
            stopOnWin: 0
        };
        this.handleInputChangePayout = this.handleInputChangePayout.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentWillUnmount() {
        let { engine, started } = this.state;
        engine.init = false;
        this._isMounted = false;

        document.removeEventListener('mousedown', this.handleClickOutside);

        try {
            if (started) {
                document.getElementById('bet').click()
            }
        }
        catch (e) { }
    }

    componentDidMount() {
        let { engine } = this.state;
        this._isMounted = true;

        document.addEventListener('mousedown', this.handleClickOutside);

        this.props.gameCoin();
        engine.on(C.BUSTED_HASH_DICE, (data) => this.busted(data));
        engine.on(C.ERROR_HASH_DICE, (data) => this.error(data));
        engine.on("stop_hash_dice", () => this.stopAuto());
        engine.on("stop_playing", () => this.stopManual());

        if (isMobile()) {
            this.setState({ mt: "mt-2" })
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {

            let value = parseFloat(this.state.payout);

            if (value < 1.0102)
                value = 1.0102;

            if (value > 2.0000)
                value = 2.0000;

            if (!isValidNumber(value))
                value = 1.0102;

            this.setState({ payout: parseFloat(value).toFixed(4) });
        }
    }

    stopManual() {
        this.setState({ started: false, buttonText: 'Roll', disabled: false });
    }

    stopAuto() {
        if (this._isMounted) {
            this.setState({ started: false, buttonText: 'Start Auto Mode', disabled: false });
        }
    }

    error(data) {
        if (this._isMounted) {
            sendNotfication(data.message, 'info', 'top-center');

            if (data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ disabled: false });

            if (this.state.started) {
                this.setState({ started: false, buttonText: 'Start Auto Mode' });
            }
        }
    }

    busted(data) {
        if (this._isMounted) {
            let { engine, bet, started } = this.state;

            let isWin = false;

            if (parseFloat(data.profit) !== 0) {
                isWin = true;
            }

            if (bet === 'auto') {
                if (started) {
                    wait(1100)
                        .then(() => {
                            engine.isWin = isWin;
                            engine.roundProfit = __.toNumber(data.profit) + __.toNumber(engine.roundProfit);
                            this.placeAutoBet();
                        })
                }
            }

            wait(1000).then(() => {
                this.setState({ disabled: false });
            })

            wait(500).then(() => {
                if (isWin) {
                    playAudio('win.mp3');
                }
                else {
                    // playAudio('lost.mp3');
                }
            })
        }
    }

    setBet(e) {
        if (this._isMounted) {

            e.preventDefault();

            let self = this;

            let { isLogged, amount, bet, started } = this.state;

            // Check User
            if (!isLogged) {
                return Event.emit('showAuthModal', true);
            }

            this.setState({ disabled: true });

            if (parseFloat(amount) <= 0) {
                this.setState({ amount: forceSatoshiFormat(0.00000010) });
            }
            else {
                amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
                this.setState({ amount: forceSatoshiFormat(amount) });
            }

            if (bet === 'auto') {
                if (started) {
                    this.setState({ started: false, buttonText: 'Start Auto Mode', disabled: false });
                    return false;
                }
                else {
                    this.setState({ started: true, buttonText: 'Stop Auto Mode' });
                    this.placeAutoBet();
                }
            }
            else {
                this.placeBet();
            }
        }
    }

    placeAutoBet() {
        if (this._isMounted) {
            let { engine, amount, payout, type, betNumber, firstBetNumber, onWin, onLost, stopOnWin } = this.state;
            wait(500).then(() => {
                engine.init = true;
                engine.amount = amount;
                engine.type = type;
                engine.payout = payout;
                engine.coin = this.props.coin;
                engine.betNumber = firstBetNumber;
                engine.stopOnWin = parseInt(stopOnWin);
                engine.onWin = parseInt(onWin);
                engine.onLost = parseInt(onLost);
                engine.autoStart();
                this.setState({ betNumber: betNumber - 1 });
            })
        }
    }

    placeBet() {
        if (this._isMounted) {
            let { amount, type, payout, engine } = this.state;

            wait(100)
                .then(() => {
                    engine.game = 'hash_dice';
                    engine.init = true;
                    engine.amount = amount;
                    engine.payout = payout;
                    engine.type = type;
                    engine.coin = this.props.coin;
                    engine.start();
                })
        }
    }

    handleChange(value) {
        if (this._isMounted) {
            this.setState({ type: value });
            Event.emit('change_type', value);
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;

        if (target.name === 'amount' || target.name === 'stopOnWin') {
            this.setState({amount:value})
            if (!isValidNumber(value)) return;
        }

        if (target.name === 'amount') {
            storage.setKey('lam', value);
        }
        else{
        this.setState({ [target.name]: value });
        }
    };

    handleInputChangePayout(event) {
        let value = event.target.value;

        if (value.length > 5) return;

        this.setState({ payout: value });
    };

    betMode = (type) => {
        let text;

        if (type === 'auto') {
            text = 'Start Auto Mode';
        }
        else {
            text = 'Roll';
        }
        this.setState({ bet: type, buttonText: text });
    };

    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am);
    }

    setNumber = (e, num) => {
        e.preventDefault();
        this.setState({ betNumber: num });
        this.setState({ firstBetNumber: num });
    };

    render() {
        let { disabled, amount, bet, type, started, mt } = this.state;
        return (
            <>
                <Form onSubmit={(e) => this.setBet(e)}>
                    <div className={" text-center btn-group-toggle mb-3 " + mt} data-toggle="buttons">
                        <label className={'' + type === 'low' ? 'btn bg-cs2 btn-xs hslb  active' : 'btn bg-cs2 btn-xs hslb '}>
                            <input disabled={disabled} onClick={e => this.handleChange('low')} type="radio" /> Low
                        </label>
                        <label className={'' + type === 'high' ? 'btn bg-cs2 btn-xs hsrb  active' : 'btn bg-cs2 btn-xs hsrb '}>
                            <input disabled={disabled} onClick={e => this.handleChange('high')} type="radio" /> High
                        </label>
                    </div>
                    <Row className={'mt-1'}>
                        <Col xl={6} md={6} sm={12}>
                            <div className="form-group mb-1">
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                                            BET
                                        </span>
                                    </div>
                                    <input
                                        disabled={disabled}
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter Bet Amount"
                                        value={amount}
                                        autoComplete={"off"}
                                        onKeyUp={this.handleInputChange}
                                        onChange={this.handleInputChange}
                                        className="form-control text-left"
                                        required={true}
                                    />
                                    <div className="input-group-append">
                                        <RangeCredit set={this.setAmount} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={6} sm={12}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">PAYOUT</span>
                                    </div>
                                    <input
                                        ref={this.wrapperRef}
                                        disabled={disabled} type="text" className="form-control text-left" id="payout"
                                        name="payout" min="2" max="9900.0" value={this.state.payout} autoComplete={"off"}
                                        onChange={this.handleInputChangePayout} required={true} />
                                    <div className="input-group-append">
                                        <button className="btn bg-cs2 after-text no-hover npt nh" type="button">x</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div>
              <span style={{fontSize:11,marginLeft:5}}>
                {this.props.coin ==='INR' ?
              "(Minimum Bet - 200 INR)"
              :
              "(Minimum Bet - 2 USDT)"
              }
              </span>
              </div>
                    <Row>
                        <Col md={5} sm={12} className="m-auto">
                            {bet === 'manual' &&
                                <div className={"form-group rev mt-2 mb-0"}>
                                    <Button variant={'btn btn-block btn-bet btn-xl mb-2'}
                                        id={'bet'}
                                        disabled={disabled}
                                        type="submit">
                                        {this.state.buttonText}
                                    </Button>
                                </div>
                            }
                        </Col>
                    </Row>
                    {bet === 'auto' &&
                        <>
                            <Row>
                                <Col xl={6} md={6} sm={12}>
                                    <div className={"form-group mb-1 bet-input"}>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    Bets Num
                                                </span>
                                            </div>
                                            <input
                                                type="number"
                                                disabled={disabled}
                                                className="form-control"
                                                required={true}
                                                value={this.state.betNumber}
                                                onChange={e => this.setNumber(e, e.target.value)} />
                                            <div className="input-group-append">
                                                <button onClick={e => this.setNumber(e, 10)} className={'btn btn-s-4 btn-xs'}>10</button>
                                                <button onClick={e => this.setNumber(e, 50)} className={'btn btn-s-4 btn-xs'}>50</button>
                                                <button onClick={e => this.setNumber(e, 100)} className={'btn btn-s-4 btn-xs'}>100</button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} md={6} sm={12}>
                                    <div className={"form-group mb-1 bet-input"}>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    Increase Amount On Win
                                                </span>
                                            </div>
                                            <input
                                                disabled={disabled}
                                                type="number"
                                                className="form-control"
                                                name="onWin"
                                                min="0"
                                                max="100"
                                                autoComplete={"off"}
                                                value={this.state.onWin}
                                                onChange={e => this.setState({ onWin: e.target.value })} />
                                            <div className="input-group-append">
                                                <button className="btn bg-cs2 after-text no-hover npt nh" type="button">%</button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} md={6} sm={12}>
                                    <div className={"form-group mb-1 bet-input"}>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    Increase Amount On Lost
                                                </span>
                                            </div>
                                            <input
                                                disabled={disabled}
                                                type="number"
                                                className="form-control"
                                                name="onLost"
                                                min="0"
                                                max="100"
                                                autoComplete={"off"}
                                                value={this.state.onLost}
                                                onChange={e => this.setState({ onLost: e.target.value })} />
                                            <div className="input-group-append">
                                                <button className="btn bg-cs2 after-text no-hover npt nh" type="button">%</button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6} md={6} sm={12}>
                                    <div className={"form-group mb-1 bet-input"}>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    Stop On Win
                                                </span>
                                            </div>
                                            <input
                                                disabled={disabled}
                                                type="text"
                                                placeholder=".."
                                                className="form-control"
                                                name="stopOnWin"
                                                value={this.state.stopOnWin}
                                                autoComplete={"off"}
                                                onChange={this.handleInputChange} />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-2 mr-1'} alt="" />
                                                    {this.props.coin}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={5} className={'m-auto'}>
                                    <div className={"form-group mt-0 mb-0 rev"}>
                                        <Button variant={'btn btn-md btn-block my-2 btn-bet'}
                                            type="submit">
                                            {this.state.buttonText}
                                            {started &&
                                                <><div className={'ml-2 spinner-border spinner-border-sm'} /></>
                                            }
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                </Form>
                <div className="btn-group btn-group-toggle mb-1 ml-1" data-toggle="buttons">
                    <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                        <input disabled={disabled} onClick={e => this.betMode('manual')} type="radio" /> Manual
                    </label>
                    <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                        <input disabled={disabled} onClick={e => this.betMode('auto')} type="radio" /> Auto
                    </label>
                </div>
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
