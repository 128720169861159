import React from "react";
import { ButtonGroup, Row, Col } from "react-bootstrap";
import User from "./../User";
import Credit from "./../Credit";
import CreditMobile from "./../Credit/Mobile";
import Logo from "./../Logo";
import Chat from "./../Chat";
import RealTime from "./../RealTime";
import MobileMenu from "./../Menu/MobileMenu";
import Notifications from "./../Notification";
import { isMobile, Event, CRISTP_ID, addNewStyle, wait } from "../../../../Helper";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header: null,
      show: true,
      loaded:false
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

   support = () => {
    var d, s;
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISTP_ID;

    if (!this.state.loaded) {
      console.log("support");
      // this.setState({loaded:true})
      (function () {
        d = document;
        s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.id = "chtt";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    }

    addNewStyle("#crisp-chatbox {display:block !important;}");
    addNewStyle(
      ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
    );

    wait(2000).then(() => {
      var close = document.querySelector(".cc-wdhl");
      if (close === null) return;
      close.addEventListener("click", function (event) {
        addNewStyle("#crisp-chatbox {display:none !important;}");
        document.getElementById("chtt").src = "";
      });
    });
  };


  handleResize() {
    if (!isMobile()) {
      this.setState({ header: this.desktop() });
    } else {
      this.setState({ header: this.mobile() });
    }
  }

  collapse = () => {
    let show = !this.state.show;
    this.setState({ show: show });
    Event.emit("toggle_sidebar", show);
  };

  desktop = () => {
    const { t } = this.props;
    return (
      <div className="topbar" id={"topbar"}>
        <nav className="navbar-custom">
          <RealTime t={t} />
          <Row>
            <Col md="5" sm="6">
            <div style={{ marginLeft: '-20px' }}> {/* Adjust the value as needed */}
        <Logo />
      </div>
              {/*<ul class="list-unstyled topbar-nav mb-0">
                                    <li>
                                        <button class="button-menu-mobile nav-link hidden-sm" onClick={this.collapse}>
                                            <i class="dripicons-menu nav-icon"></i>
                                         </button>
                                    </li>
                                </ul>*/}
            </Col>
            <Col md="4" sm="6">
              <Credit t={t} />
            </Col>
            <Col md="2" sm="6">
              <ButtonGroup>
                <User t={t} />
                <button className="btn btn-user notif-btn" onClick={()=>this.support()}>
                <img className="img-fluid bell-img" src="/assets/images/chaticon.png" style={{ width: '30px',marginTop:10, height: '30px',textAlign:'right' }} />
                          
              </button>
                {/* <Notifications t={t} /> */}
              </ButtonGroup>
            </Col>
            {/* <Col md="1" sm="6">
              <ul className="list-unstyled topbar-nav mb-0 mr-1 float-right">
                <ButtonGroup>
                  <Chat t={t} />
                </ButtonGroup>
              </ul>
            </Col> */}
          </Row>
        </nav>
      </div>
    );
  };

  mobile = () => {
    const { t } = this.props;
    return (
      <div className="topbar" id={"topbar"}>
        <nav className="navbar-custom">
          <MobileMenu t={t} />
          <Logo />
          <ul className="list-unstyled topbar-nav float-right mb-0 mr-1  top-header-mobile">
            <RealTime t={t} />
            <CreditMobile t={t} />
            {/*<ButtonGroup>
                                <Notifications t={t} />
                            </ButtonGroup>*/}
            <ButtonGroup>
              <User t={t} />
              <button className="btn btn-user notif-btn"onClick={()=>this.support()}>
                <img className="img-fluid bell-img" src="/assets/images/chaticon.png" style={{ width: '30px', height: '30px',marginTop:'-5px',textAlign:'right' }} />
              </button>
              {/* <Notifications t={t} /> */}
            </ButtonGroup>
          </ul>
        </nav>
      </div>
    );
  };

  render() {
    return this.state.header;
  }
}

export default Header;
