import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Spinner, Alert, FormControl, Container, Row, Col } from "react-bootstrap";
import Carousel from "../../Parts/Home/crousel";
import { Helmet } from 'react-helmet';
import Popular from "./Popular";
import Games from "./Games";
import Slots from "../../../Slots/Slots";
import { BRAND, isMobile } from "../../../../Helper";
import { withCookies } from 'react-cookie';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #1b2336;
`;

const FullScreenIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  display: ${(props) => (props.loading ? 'none' : 'block')};
`;

const BackButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: none;
  border: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
`;

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #000;
`;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      padding: 'py-2',
      selectedSection: 'home',
      sectionIndex: 0,
      slideSelected: null,
      gamesEvolution: [],
      gamesPragmatic: [],
      gamesPragmaticlive: [],
      gamesEvoplay: [],
      gamesGenisis: [],
      gamesPgsoft: [],
      gamesLiveCasinoProvider: [],
      gamesSpribe: [],
      gamesSports: [],
     gamesMostExpensive:[],
      error: null,
      loading: false,
      message: null,
      showMore: false,
      showMoreP: false,
      showMoreO: false,
      showMoreLCP: false,
      showMoreSP: false,
      showMoreSB: false,
      isMobileView: window.innerWidth <= 768,
      searchQuery: '',
      showIframe: false,
      iframeUrl: '',
      iframeLoading: true, 
    };
    this.sections = ["/assets/images/evolutionicon.png", "/assets/images/ezubi.png", "/assets/images/pragmatic.png", "/assets/images/netent.png", "/assets/images/novomatic.png"];
    this.livecasino = ["/assets/images/live1.png", "/assets/images/live2.png", "/assets/images/live3.jpg", "/assets/images/live4.jpg", "/assets/images/live5.jpg", "/assets/images/live6.png"];
    this.itemsToShow = 5;
  }

  handleGameLaunch = async (gameCode, provider) => {
    this.setState({ loading: true, message: null });
    try {
      const { cookies } = this.props;
      const uid = cookies.get('uid');

      if (!uid) {
        throw new Error("Please login to play live game");
      }

      const response = await fetch('https://api.ibitplay.com/game_launch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_code: String(uid),
          provider_code: provider,
          game_code: gameCode
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.status === 1) {
        this.setState({ iframeUrl: result.launch_url, showIframe: true, iframeLoading: true  });
      } else if (result.status === 0 && result.detail === "user_code is not allowed to be empty") {
        throw new Error("Please login to play live game");
      } else {
        throw new Error("Failed to launch game: " + result.msg);
      }
    } catch (error) {
      this.setState({ message: { type: 'error', text: error.message } });
    } finally {
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  };

  handleGameLaunchNew = async (gameCode, provider) => {
    this.setState({ loading: true, message: null });
    try {
      const { cookies } = this.props;
      const uid = cookies.get('uid');
      if (!uid) {
        throw new Error("Please login to play live game");
      }
      const response = await fetch('https://api.ibitplay.com/game_launch_new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_code: String(uid),
          game_code: gameCode
        }),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
  
      if (result.status === "success" && result.content && result.content.game && result.content.game.url) {
        this.setState({ iframeUrl: result.content.game.url, showIframe: true , iframeLoading: true });
      } else if (result.status === "fail" && result.detail === "user_code is not allowed to be empty") {
        throw new Error("Please login to play live game");
      } else {
        throw new Error("Failed to launch game: " + result.error);
      }
    } catch (error) {
      this.setState({ message: { type: 'error', text: error.message } });
    } finally {
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  };

  handleBackButton = () => {
    this.setState({ showIframe: false, iframeUrl: '', iframeLoading: true });  
  };
  
  handleIframeLoad = () => {
    this.setState({ iframeLoading: false });
  };
  
  componentDidMount() {
    this._isMounted = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (isMobile()) {
      this.setState({ padding: 'p-1' });
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.fetchGames("EVOLUTION").then(games => {
      this.setState({ gamesEvolution: games.map(game => ({ ...game, provider_code: "EVOLUTION" })) });
    });
    this.fetchGames("PRAGMATICLIVE").then(games => {
      this.setState({ gamesPragmatic: games.map(game => ({ ...game, provider_code: "PRAGMATICLIVE" })) });
    });
    this.fetchGames("PRAGMATIC").then(games => {
      this.setState({ gamesPragmaticlive: games.map(game => ({ ...game, provider_code: "PRAGMATIC" })) });
    });
    this.fetchGames("EVOPLAY").then(games => {
      this.setState({ gamesEvoplay: games.map(game => ({ ...game, provider_code: "EVOPLAY" })) });
    });
    this.fetchGames("DREAMTECH").then(games => {
      this.setState({ gamesGenisis: games.map(game => ({ ...game, provider_code: "DREAMTECH" })) });
    });
    this.fetchGames("PGSOFT").then(games => {
      this.setState({ gamesPgsoft: games.map(game => ({ ...game, provider_code: "PGSOFT" })) });
    });
    // this.fetchGamesNew("live_dealers").then(games => {
    //   const filteredGames = games.filter((game, index) => [1,6,0].includes(index));
    //   this.setState({ gamesLiveCasinoProvider: filteredGames.map(game => ({ ...game, provider_code: "live_dealers" })) });
    // });
    this.fetchGamesNew("spribe").then(games => {
      const filteredGames = games.filter((game, index) => [8,5,6,7].includes(index));
      this.setState({ gamesSpribe: filteredGames.map(game => ({ ...game, provider_code: "spribe" })) });
    });   
    // this.fetchGamesNew("more_expensive").then(games => {
    //   this.setState({ gamesMostExpensive: games.map(game => ({ ...game, provider_code: "more_expensive" })) });
    //   gamesLiveCasinoProvider.concat(gamesMostExpensive);
    // }); 
this.fetchGamesNew("more_expensive").then(moreExpensiveGames => {
  const updatedMoreExpensiveGames = moreExpensiveGames.map(game => ({ ...game, provider_code: "more_expensive" }));
  this.setState(prevState => ({
    gamesMostExpensive: updatedMoreExpensiveGames,
    gamesLiveCasinoProvider: (prevState.gamesLiveCasinoProvider || []).concat(updatedMoreExpensiveGames)
  }));
});
this.fetchGamesNew("live_dealers").then(liveDealerGames => {
  const filteredLiveDealerGames = liveDealerGames.filter((game, index) => [1, 6, 0].includes(index));
  const updatedLiveDealerGames = filteredLiveDealerGames.map(game => ({ ...game, provider_code: "live_dealers" }));
  this.setState(prevState => ({
    gamesLiveCasinoProvider: (prevState.gamesLiveCasinoProvider || []).concat(updatedLiveDealerGames)
  }));
});

    this.fetchGamesNew("sport_betting").then(games => {
      this.setState({ gamesSports: games.map(game => ({ ...game, provider_code: "sport_betting" })) });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this._isMounted = false;
  }

  fetchGames = async (provider) => {
    try {
      const response = await fetch(`https://api.ibitplay.com/game-list?s=${provider}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      return result.games;
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
      return [];
    }
  };

  fetchGamesNew = async (provider) => {
    try {
      const response = await fetch(`https://api.ibitplay.com/game-list-new?lable=${provider}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      
      const result = await response.json();
      if (result.games && result.games[provider]) {
        return result.games[provider];
      } else {
        throw new Error(`No games found for the provider: ${provider}`);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
      return [];
    }
  };

  handleResize = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.setState({ isMobileView: width <= 768 });
  };

  handleClick = (e) => {
    this.setState({ selectedSection: e });
  };

  handleArrowClick = (direction) => {
    const { sectionIndex } = this.state;
    const { sections, itemsToShow } = this;
    const totalSections = sections.length;

    let newIndex;

    if (direction === 'left') {
      newIndex = (sectionIndex - itemsToShow + totalSections) % totalSections;
    } else {
      newIndex = (sectionIndex + itemsToShow) % totalSections;
    }

    this.setState({ selectedSection: sections[newIndex], sectionIndex: newIndex });
  };

  handleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  handleShowMoreP = () => {
    this.setState({ showMoreP: !this.state.showMoreP });
  };

  handleShowMoreO = () => {
    this.setState({ showMoreO: !this.state.showMoreO });
  };

  handleShowMoreLCP = () => {
    this.setState({ showMoreLCP: !this.state.showMoreLCP });
  };

  handleShowMoreSP = () => {
    this.setState({ showMoreSP: !this.state.showMoreSP });
  };

  handleShowMoreSB = () => {
    this.setState({ showMoreSB: !this.state.showMoreSB });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };

  getFilteredGames = (games) => {
    const { searchQuery } = this.state;
    if (!searchQuery) return games;
    return games.filter(game => game.game_name && game.game_name.toLowerCase().includes(searchQuery.toLowerCase()));
  };
  render() {
    let { content, t } = this.props;
    const { selectedSection, sectionIndex, slideSelected, gamesEvolution, gamesLiveCasinoProvider, gamesSpribe, gamesPragmatic, gamesPragmaticlive, gamesSports, gamesEvoplay, gamesGenisis, gamesPgsoft, gamesMostExpensive,loading, message, searchQuery, showMore, showMoreP, showMoreO, showMoreLCP, showMoreSP, showMoreSB, isMobileView, padding, showIframe, iframeUrl,iframeLoading } = this.state;
    const { sections, itemsToShow, livecasino } = this;

    const visibleSections = sections.slice(sectionIndex, sectionIndex + itemsToShow);

    const filteredGamesEvolution = this.getFilteredGames(gamesEvolution);
    const filteredGamesPragmatic = this.getFilteredGames(gamesPragmatic);
    const filteredGamesEvoplay = this.getFilteredGames(gamesEvoplay);
    const filteredGamesGenisis = this.getFilteredGames(gamesGenisis);
    const filteredGamesPgsoft = this.getFilteredGames(gamesPgsoft);
    const filteredSpribe = this.getFilteredGames(gamesSpribe);
    const filteredSports = this.getFilteredGames(gamesSports);
    const filtergamespragamaticlive = this.getFilteredGames(gamesPragmaticlive);
    const filtergamesLiveCasinoProvider = this.getFilteredGames(gamesLiveCasinoProvider);
    const displayedGames = isMobileView ? gamesEvolution : (showMore ? gamesEvolution : gamesEvolution.slice(0, 5));
    const displayedGamesSP = isMobileView ? gamesSpribe : (showMoreSP ? gamesSpribe : gamesSpribe.slice(0, 5));
    const displayedGamesSB = isMobileView ? gamesSports : (showMoreSB ? gamesSports : gamesSports.slice(0, 5));
    const displayedGamesLCP = isMobileView ? gamesLiveCasinoProvider: (showMoreLCP ? gamesLiveCasinoProvider : gamesLiveCasinoProvider.slice(0, 5));
    const displayGamesP = isMobileView ? gamesPragmatic : (showMoreP ? gamesPragmatic : gamesPragmatic.slice(0, 5));
    const displayGamesPL = isMobileView ? gamesPragmaticlive : (showMoreO ? gamesPragmaticlive : gamesPragmaticlive.slice(0, 5));
    
    // if (showIframe) {
    //   return (
    //     <FullScreenContainer>
    //       {this.state.iframeLoading && (
    //         // <Loader>
    //         //   <FontAwesomeIcon icon={faSpinner} spin />
    //         // </Loader>
    //         <img src="assets/images/lg.png" alt="Loading" style={imageStyle} />
    //       )}
    //       <FullScreenIframe
    //         src={iframeUrl}
    //         title="Game Iframe"
    //         onLoad={this.handleIframeLoad}  
    //         loading={this.state.iframeLoading} 
    //       />
    //       <BackButton onClick={this.handleBackButton}>
    //         <FontAwesomeIcon icon={faArrowLeft} />
    //       </BackButton>
    //     </FullScreenContainer>
    //   );
    // }
    const imageStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '250px', 
      animation: 'fadeinout 2s infinite',
    };
    const animationStyle = `
      @keyframes fadeinout {
        0%, 100% { opacity: 0; }
        50% { opacity: 1; }
      }
    `;
    if (showIframe) {
      return (
        <FullScreenContainer>
          <style>{animationStyle}</style>
          {this.state.iframeLoading && (
            <img src="assets/images/lg.png" alt="Loading" style={imageStyle} />
          )}
          <FullScreenIframe
            src={iframeUrl}
            title="Game Iframe"
            onLoad={this.handleIframeLoad}  
            loading={this.state.iframeLoading}
          />
          <BackButton onClick={this.handleBackButton}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </BackButton>
        </FullScreenContainer>
      );
    }
    
    return (
      <>
        {this._isMounted &&
          <>
            <Helmet>
              <title>{BRAND} - Crypto Casino Games and Crypto Gambling</title>
              <meta name="keywords" content="WEB 3.0 Crypto Casino Games, Online Casino Games, Bitcoin Crash Game, Dice Games, Live Casino Games, Crypto Casino Games, Blockchain Games, Bitcoin Casino Games, Best Crypto Online Game, Crypto Gambling Games, PlayCrash.com Crypto Games" />
              <meta name="description"
                content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
              <meta name="og:title" content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
              <meta name="og:description" content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
            </Helmet>

            {/* Page Content */}
            {content}

            <div style={{ marginTop: -20 }}>
              <Carousel />
                {isMobileView && ( // Render only if it's mobile view
          <Card className="brd7" style={{ marginTop: -5, marginBottom: 0 }}>
            <Card.Body className="py-2" style={{ display: 'flex', width: '100%', alignSelf: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: 10,
                  backgroundColor: selectedSection === 'home' ? '#90909020' : 'transparent',
                  paddingTop: '2px', // Adjust the top padding as needed
                  paddingBottom: '2px', // Adjust the bottom padding as needed
                }}
                onClick={() => this.handleClick('home')}
              >
                <img src="/assets/images/homeicon.png" style={{ height: 16, width: 16, marginRight: -10, marginLeft: 3, color: 'yellow' }} />
                <h6 style={{ fontSize: 12 }} className="section-title">Home</h6>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: selectedSection === 'originals' ? '#90909020' : 'transparent',
                  borderRadius: 10,
                  paddingTop: '2px', // Adjust the top padding as needed
                  paddingBottom: '2px', // Adjust the bottom padding as needed
                }}
                onClick={() => this.handleClick('originals')}
              >
                <img src="/assets/images/originalsicon.png" style={{ height: 16, width: 16, marginRight: -10, marginLeft: 3, color: 'yellow' }} />
                <h6 style={{ fontSize: 12 }} className="section-title">ORIGINALS</h6>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: selectedSection === 'casino' ? '#90909020' : 'transparent',
                  borderRadius: 10,
                  paddingTop: '2px', // Adjust the top padding as needed
                  paddingBottom: '2px', // Adjust the bottom padding as needed
                }}
                onClick={() => this.handleClick('casino')}
              >
                <img src="/assets/images/casinoicon.png" style={{ height: 16, width: 16, marginRight: -10, marginLeft: 3, color: 'yellow' }} />
                <h6 style={{ fontSize: 12 }} className="section-title">CASINO</h6>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: selectedSection === 'sports' ? '#90909020' : 'transparent',
                  borderRadius: 10,
                  paddingTop: '2px', // Adjust the top padding as needed
                  paddingBottom: '2px', // Adjust the bottom padding as needed
                }}
                onClick={() => this.handleClick('sports')}
              >
                <img src="/assets/images/sportsicon.png" style={{ height: 16, width: 16, marginRight: -10, marginLeft: 3, color: 'yellow' }} />
                <h6 style={{ fontSize: 12 }} className="section-title">SPORTS</h6>
              </div>
            </Card.Body>
          </Card>
        )}

              {/* Search Bar */}
            {/*} <Container fluid className="py-2">
                <Row>
                  <Col xs={12} className="px-0">
                    <div style={{ position: 'relative', width: '100%' }}>
                      <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '8px',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#555" viewBox="0 0 16 16">
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </div>
                      <FormControl
                        type="text"
                        placeholder="Search games..."
                        value={searchQuery}
                        onChange={(e) => this.handleSearch(e.target.value)}
                        style={{
                          padding: '2px 5px 2px 28px',
                          height: '28px',
                          width: 'calc(100% - 10px)',
                          margin: '0 5px',
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>}

              {/* Filtered Games Display */}
              {searchQuery && (
                <Card className="brd7">
                  <Card.Body className={this.state.padding}>
                    <h4 className="mt-2 mb-2 section-title"> Search Results</h4>
                    <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
                      <div style={{
                        display: 'flex',
                        flexWrap: isMobileView ? 'nowrap' : 'wrap',
                        width: '100%',
                        overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
                        WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
                        msOverflowStyle: 'none', // hide scrollbar in IE and Edge
                        scrollbarWidth: 'none', // hide scrollbar in Firefox
                      }}>
                        {filteredGamesEvolution.concat(filteredGamesPragmatic, filteredGamesEvoplay, filteredGamesGenisis, filteredGamesPgsoft, filtergamespragamaticlive,).map((game, index) => (
                          <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                            <img src={game.banner} style={{ width: '150px', height: '175px', borderRadius: 10, marginBottom: '10px' }} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )}

              <Card className="brd7">
                <Card.Body className={this.state.padding}>
                  <h4 className="mt-2 mb-2 section-title"> Most Popular</h4>
                  <Popular t={t} />
                </Card.Body>
              </Card>
             
               {isMobileView && ( // Render only if it's mobile view
          <Card style={{ marginTop: -15, marginBottom: 10, width: '110%', marginLeft: -10 }}>
            <Card.Body className={padding}>
              <div className="d-flex w-full" style={{ overflow: 'hidden' }}>
                <div style={{ display: 'flex', width: '100%', overflowY: 'scroll' }}>
                  {visibleSections.map((section, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        borderRadius: 10,
                        backgroundColor: slideSelected === section ? "#90909020" : 'transparent',
                        paddingTop: '2px', // Adjust the top padding as needed
                        paddingBottom: '2px', // Adjust the bottom padding as needed
                      }}
                      onClick={() => this.handleClick(section)}
                    >
                      <img src={section} style={{ width: '117px', height: 'auto', marginRight: 10, marginLeft: 3 }} />
                    </div>
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>
        )}

{/* <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">Live Casino Providers</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayedGamesLCP.map((game, index) => (
                <div key={game.id} onClick={() => this.handleGameLaunchNew(game.id, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                  <img src={game.img} style={{ width: '125px', height: '145px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filtergamesLiveCasinoProvider.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreLCP}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreLCP ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>                */}

{/* <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">EVOLUTION</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayedGames.map((game, index) => (
                <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                  <img src={game.banner} style={{ width: '150px', height: '175px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filteredGamesEvolution.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMore}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMore ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>  */}

      <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">Spribe</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayedGamesSP.map((game, index) => (
                <div key={game.id} onClick={() => this.handleGameLaunchNew(game.id, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                  <img src={game.img} style={{ width: '85px', height: '85px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filteredSpribe.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreSP}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreSP ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      
       
      {/* <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">PRAGMATIC LIVE</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayGamesP.map((game, index) => (
                <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
                  <img src={game.banner} style={{ width: '135px', height: '155px', borderRadius: 10, marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: 3 }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filteredGamesPragmatic.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreP}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreP ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card> 

      <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">PRAGMATIC</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayGamesPL.map((game, index) => (
                <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
                  <img src={game.banner} style={{ width: '150px', height: '175px', borderRadius: 10, marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: 3 }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filtergamespragamaticlive.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreO}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreO ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card> */}

              {<Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
                <Card.Body className={this.state.padding}>
                  <h4 className="mt-2 mb-2 section-title">SLOTS</h4>

                  {/* Evoplay Games */}
                  <div className="d-flex w-full" style={{ overflow: 'hidden', height: '130px', marginBottom: '3px' }}>
                    <div style={{ display: 'flex', width: '100%', overflowY: 'auto', scrollbarColor: 'transparent transparent', scrollbarWidth: 'none' }}>
                      {filteredGamesEvoplay.map((game) => (
                        <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
                          <img src={game.banner} style={{ width: '95px', height: '120px', borderRadius: 10, marginRight: 10, marginLeft: 3 }} />
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Genesis Games */}
                  <div className="d-flex w-full" style={{ overflow: 'hidden', height: '130px', marginBottom: '3px' }}>
                    <div style={{ display: 'flex', width: '100%', overflowY: 'auto', scrollbarColor: 'transparent transparent', scrollbarWidth: 'none' }}>
                      {filteredGamesGenisis.map((game) => (
                        <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
                          <img src={game.banner} style={{ width: '95px', height: '120px', borderRadius: 10, marginRight: 10, marginLeft: 3 }} />
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Pgsoft Games */}
                  <div className="d-flex w-full" style={{ overflow: 'hidden', height: '130px' }}>
                    <div style={{ display: 'flex', width: '100%', overflowY: 'auto', scrollbarColor: 'transparent transparent', scrollbarWidth: 'none' }}>
                      {filteredGamesPgsoft.map((game) => (
                        <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
                          <img src={game.banner} style={{ width: '95px', height: '120px', borderRadius: 10, marginRight: 10, marginLeft: 3 }} />
                        </div>
                      ))}
                    </div>
                  </div>
                </Card.Body>
              </Card>
  }                  
      {/*  <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">Sports</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayedGamesSB.map((game, index) => (
                <div key={game.id} onClick={() => this.handleGameLaunchNew(game.id, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                  <img src={game.img} style={{ width: '135px', height: '165px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filteredSports.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreSB}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreSB ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>*/}
              <Card className="brd7" id="originals">
                <Card.Body className={this.state.padding + ' pb-3'}>
                  <h4 className="mt-2 mb-2 section-title"> {t('originals')} Games</h4>
                  <Games t={t} />
                </Card.Body>
              </Card>
              <Card className="brd7">
                <Card.Body className={this.state.padding}>
                  <h4 className="mt-2 mb-2 section-title"> Free Slots</h4>
                  <Slots home={true} />
                  <div className="text-center">
                    <Link className="btn btn-more btn-sm px-4 mt-1 mb-3" to="/slots">
                      <i className="mdi mdi-arrow-right-bold-box align-middle" /> Show More
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </>
        }

        {/* Loader */}
        {loading && (
          <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}

        {/* Snackbar */}
        {message && (
          <Alert variant={message.type === 'error' ? 'danger' : 'success'} style={{ position: 'fixed', bottom: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 9999 }}>
            {message.text}
          </Alert>
        )}
      </>
    );
  }
}

export default withCookies(Home);
