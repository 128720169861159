import React from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    games: {
        width: 38,
        marginRight: 5,
        marginTop: 6,
        color:'#ffbf09'
    }
});

export default function Games() {
    const classes = useStyles();
    return <img src="/assets/images/new_icons/originalgames.png" className={classes.games} alt="Games" />
}
